import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import CustomInput from '../components/CustomInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { getBrands } from '../features/brand/brandSlice';
import { getCategories } from '../features/pcategory/pcategorySlice';
import { getColors } from '../features/color/colorSlice';
import { Select } from 'antd';
import Dropzone from 'react-dropzone'
import { delImg, uploadImg } from '../features/upload/uploadSlice';
import { createProducts, resetState } from '../features/product/productSlice';
import { useNavigate } from 'react-router-dom';


const schema = Yup.object().shape({
  title: Yup.string().required('Please, Enter Title'),
  description: Yup.string().required('Please, Enter Description'),
  price: Yup.number().required('Please, Enter Price'),
  brand: Yup.string().required('Please, Enter Brand'),
  category: Yup.string().required('Please, Enter category'),
  tags: Yup.string().required('Please, Select Tag'),
  color: Yup.array().min(1, 'Pick at least one color').required('Please Enter Color'),
  quantity: Yup.number().required('Please, Enter Qauntity'),
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(getColors())
  }, []);

  const brandState = useSelector((state) => state.brand.brands);
  const categoryState = useSelector((state) => state.pCategory.pCategories);
  const colorState = useSelector((state) => state.color.colors);
  const imageState = useSelector((state) => state.upload.images);
  const newProduct = useSelector((state) => state.product);
  const { isSuccess, isError, isLoading, createdProduct } = newProduct;
  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success('Done easy!');
    } if (isError) {
      toast.error('Sorry, Something is Wrong!');
    }
  },[isSuccess, isError, isLoading]);

  const coloropt = [];
  colorState.forEach((i) => {
    coloropt.push({
      label: i.title,
      value: i._id,
    });
  });
  const img = [];
  imageState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
 
  useEffect(() => {
    formik.values.color = color ? color : ' ';
    formik.values.images = img;
  }, [color, img]);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      price: '',
      brand: '',
      category: '',
      tags: '',
      color: '',
      quantity: '',
      images: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(createProducts(values));
      formik.resetForm();
      setColor(null);
      setTimeout(() => {
        dispatch(resetState())
        navigate('/admin/product-list');
      },3000);
    },
  });

  const handleColors = (e) => {
    setColor(e);
    console.log(color);
  }

  return (
    <div>
      <h3 className='mb-4'>Add Product</h3>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput type='text' label='Enter Product Title' name='title' onCh={formik.handleChange('title')} onBlr={formik.handleBlur('title')} val={formik.values.title} />
          <div className='error mt-2'>
            {formik.touched.title && formik.errors.title}
          </div> 
          <div className='mb-3'>
          <ReactQuill theme="snow" name='description' onChange={formik.handleChange('description')} value={formik.values.description} />
          </div>
          <div className='error mt-2'>
            {formik.touched.description && formik.errors.description}
          </div>
          <CustomInput type='number' label='Enter Product Price' name='price' onCh={formik.handleChange('price')} onBlr={formik.handleBlur('price')} val={formik.values.price} />
          <div className='error mt-2'>
            {formik.touched.price && formik.errors.price}
          </div> 
          <select name='brand' id='brand' className='form-control py-3 mb-3' onChange={formik.handleChange('brand')} onBlur={formik.handleBlur('brand')} value={formik.values.brand}>
            <option value=''>Select Brand</option>
            {brandState.map((i, j) => {
                return <option key={j} value={i.title}>{i.title}</option>
              })
            }
          </select>
          <div className='error mt-2'>
            {formik.touched.brand && formik.errors.brand}
          </div> 
          <select name='category' className='form-control py-3 mb-3' id='category' onChange={formik.handleChange('category')} onBlur={formik.handleBlur('category')} value={formik.values.category}>
            <option value=''>Select Category</option>
            {
              categoryState.map((i, j) => {
                return <option key={j} value={i.title}>{i.title}</option>
              })
            }
          </select>
          <div className='error mt-2'>
            {formik.touched.category && formik.errors.category}
          </div>
          <select name='tags' className='form-control py-3 mb-3' id='tags' onChange={formik.handleChange('tags')} onBlur={formik.handleBlur('tags')} value={formik.values.tags}>
            <option value='' disabled>Select Tags</option>
            <option value='featured'>Featured</option>
            <option value='popular'>Popular</option>
            <option value='special'>Special</option>
            <option value='electronics'>Electronics</option>
            <option value='beauty'>Beauty</option>
            <option value='articles'>Articles</option>
            <option value='chemicals'>Chemicals</option>
            <option value='machinery'>Machinery</option>
            <option value='sports'>Sports</option>
            <option value='culture'>Culture</option>
            <option value='home'>Home</option>
            <option value='clothings'>Clothings</option>
            <option value='energy'>Energy</option>
            <option value='educational'>Educational</option>
            <option value='arts'>Arts</option>
          </select>  
          <div className='error mt-2'>
            {formik.touched.tags && formik.errors.tags}
          </div>
          <Select mode='multiple' allowClear className='w-100' placeholder='Select Colors' defaultValue={color} onChange={(i) => handleColors(i)}  options={coloropt}/>
          <div className='error'>
            {formik.touched.color && formik.errors.color}
          </div> 
          <CustomInput type='number' label='Enter Quantity' name='quantity' onCh={formik.handleChange('quantity')} onBlr={formik.handleBlur('quantity')} val={formik.values.quantity} />
          <div className='error'>
            {formik.touched.quantity && formik.errors.quantity}
          </div> 
          <div className='bg-dark border-1 p-3 mt-3 text-center text-white'>
            <Dropzone onDrop={acceptedFiles => dispatch(uploadImg(acceptedFiles))}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className='show-images mt-4 d-flex flex-wrap gap-3'>
            {imageState.map((i, j) => {
              return (
                <div key={j} className='position-relative'><button type='button' onClick={() => dispatch(delImg(i.public_id))} className='btn-close position-absolute' style={{top:'4px', right:'4px'}}></button><img src={i.url} alt='' width={200} height={200}/></div>
              )
            })
            }
          </div>
          <button className='btn btn-success border-0 rounded-3 my-5' type='submit'>Add Product</button>
        </form>
      </div>
    </div>
  )
}

export default Addproduct
