import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import { getOrders, updateAOrder } from '../features/auth/authSlice';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Product',
    dataIndex: 'product',
  },
  {
    title: 'Total Price',
    dataIndex: 'price',
  },
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];


const Orders = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrders());
  }, []);
  const orderState = useSelector((state) => state?.auth?.orders);
  const data1 = [];
  for (let i = 0; i < orderState?.length; i++) {
    data1.push({
      key: i + 1,
      name: orderState[i]?.user?.firstname + ' ' + orderState[i]?.user?.lastname,
      product: <Link to={`/admin/order/${orderState[i]?._id}`}>View Orders</Link>,
      price: orderState[i]?.totalPrice,
      date: new Date(orderState[i]?.createdAt).toLocaleString(),
      action: 
      <>
      <select defaultValue={orderState[i]?.orderStatus} onChange={(e)=>updateOrderStatus(orderState[i]?._id,e.target.value)} id='' className='control-form form-select' name=''>
        <option value='Ordered' disabled selected>Ordered</option>
        <option value='Processed'>Processed</option>
        <option value='Shipped'>Shipped</option>
        <option value='Out for Delivery'>Out for Delivery</option>
        <option value='Delivered'>Delivered</option>
      </select>
      </>,
    });
  }
  const updateOrderStatus = (a,b) => {
    dispatch(updateAOrder({id:a,status:b}))
  }
  return (
    <div>
      <h3 className='mb-4'>Orders</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  )
}

export default Orders
