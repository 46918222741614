import React, { useEffect } from 'react';
import CustomInput from '../components/CustomInput';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewblogCat, getABlogCat, resetState, updateABlogCat } from '../features/bcategory/bcategorySlice';

const schema = Yup.object().shape({
  title: Yup.string().required('Please, Enter Blog Category Name'),
});

const Addblogcat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();  
  const getBCatId = location.pathname.split('/')[3];
  const newBlogCategory = useSelector((state) => state.bCategory);
  const { isSuccess, isError, isLoading, createBlogCategory, blogCatName, updatedBlogCategory } = newBlogCategory;
  useEffect(() => {
    if (getBCatId !== undefined) {
      dispatch(getABlogCat(getBCatId));
    } else {
      dispatch(resetState());
    }
  }, [getBCatId]);
  useEffect(() => {
    if (isSuccess && createBlogCategory) {
      toast.success('Done easy!');
    }  if (isSuccess && updatedBlogCategory) {
      toast.success('Done updated!');
      navigate('/admin/blog-category-list');
    } if (isError) {
      toast.error('Sorry, Something is Wrong!');
    }
  },[isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: blogCatName || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getBCatId !== undefined) {
        const data = { id: getBCatId, blogCatData: values};
        dispatch(updateABlogCat(data));
        dispatch(resetState())
      } else {
        dispatch(createNewblogCat(values));
      formik.resetForm();
      setTimeout(() => {
        dispatch(resetState())
      },300);
      }
    },
  });

  return (
    <div>
      <h3 className='mb-4'>{getBCatId !== undefined ? 'Edit' : 'Add'} Blog Category</h3>
      <div>
        <form action='' onSubmit={formik.handleSubmit}>
          <CustomInput type='text' label='Enter Blog Category' name='title' onCh={formik.handleChange('title')} onBlur={formik.handleBlur('title')} val={formik.values.title} />
          <div className='error mt-2'>
            {formik.touched.title && formik.errors.title}
          </div>
          <button className='btn btn-success border-0 rounded-3 my-5' type='submit'>{getBCatId !== undefined ? 'Edit' : 'Add'} Blog Category</button>
        </form>
      </div>
    </div>
  )
}

export default Addblogcat
