import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import { FiDelete } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import {Link} from 'react-router-dom';
import { deleteAEnquiry, getEnquiries, resetState, updateAEnquiry } from '../features/enquiry/enquirySlice';
import CustomModal from '../components/CustomModal';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.email.length - b.email.length,
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];

const Enquiries = () => {
  const [open, setOpen] = useState(false);
  const [enqId, setenqId] = useState('');
  const showModal = (e) => {
    setOpen(true);
    setenqId(e);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getEnquiries());
  }, []);
  const enqState = useSelector((state) => state.enquiry.enquiries);
  const data1 = [];
  for (let i = 0; i < enqState.length; i++) {
    data1.push({
      key: i + 1,
      name: enqState[i].name,
      email: enqState[i].email,
      mobile: enqState[i].mobile,
      comment: enqState[i].comment,
      status: (
        <>
          <select name='' defaultValue={enqState[i].status ? enqState[i].status: 'Submitted'} id='' className='form-control form-select' onChange={(e) => setEnquiryStatus(e.target.value, enqState[i]._id)}>
            <option value='Submitted' >Submitted</option>
            <option value='Contacted'>Contacted</option>
            <option value='In Progress'>In Progress</option>
            <option value='Resolved' >Resolved</option>
          </select>
        </>
      ),
      action: <><Link to={`/admin/enquiries/${enqState[i]._id}`} className='ms-3 text-dark'><AiOutlineEye /></Link><button onClick={() => showModal(enqState[i]._id)} className='ms-3 text-danger bg-transparent border-0'><FiDelete /></button></>,
    });
  }
  const setEnquiryStatus = (e, i) => {
    const data = {id: i, enqData: e};
    dispatch(updateAEnquiry(data));
  }
  const deleteEnquiries = (e) => {
    setOpen(false);
    dispatch(deleteAEnquiry(e));
    setTimeout(() => {
      dispatch(getEnquiries());
    },100);
  };
  return (
    <div>
      <h3 className='mb-4'>Enquiries</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal hideModal={hideModal} open={open} title='Are you sure to delete it?' action={() => {deleteEnquiries(enqId)}} />
    </div>
  )
}

export default Enquiries
