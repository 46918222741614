import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import { getAEnquiry, resetState, updateAEnquiry } from '../features/enquiry/enquirySlice';

const ViewEnq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const getEnqId = location.pathname.split('/')[3];
  const enqState = useSelector((state) => state.enquiry);
  const {enqName, enqMobile, enqEmail, enqComment, enqStatus} = enqState;

  useEffect(() => {
    dispatch(getAEnquiry(getEnqId));
  }, [getEnqId]);
  const goBack = () => {
    navigate(-1);
  };
  const setEnquiryStatus = (e, i) => {
    const data = {id: i, enqData: e};
    dispatch(updateAEnquiry(data));
    dispatch(resetState());
    setTimeout(() => {
      dispatch(getAEnquiry(getEnqId));
    }, 100);
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='mb-4'>View Enquiry</h3>
        <button onClick={goBack} className='bg-transparent border-0 text-primary text-decoration-underline d-flex align-items-center'>Go Back</button>
      </div>
      <div className='mt-5 bg-white p-4 d-flex gap-3 flex-column rounded-3'>
        <div className='d-flex align-items-center gap-3'>
          <h5 className='mb-0'>Name:</h5>
          <p className='mb-0'>{enqName}</p>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <h5 className='mb-0'>Mobile:</h5>
          <p className='mb-0'><a href={`tel:+49{enqMobile}`}>{enqMobile}</a></p>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <h5 className='mb-0'>Email:</h5>
          <p className='mb-0'><a href={`mailto:{enqEmail}`}>{enqEmail}</a></p>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <h5 className='mb-0'>Comment:</h5>
          <p className='mb-0'>{enqComment}</p>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <h5 className='mb-0'>Status:</h5>
          <p className='mb-0'>{enqStatus}</p>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <h5 className='mb-0'>Change Status:</h5>
          <select name='' defaultValue={enqState ? enqState: 'Submitted'} id='' className='form-control form-select' onChange={(e) => setEnquiryStatus(e.target.value, getEnqId)}>
            <option value='Submitted' >Submitted</option>
            <option value='Contacted'>Contacted</option>
            <option value='In Progress'>In Progress</option>
            <option value='Resolved' >Resolved</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default ViewEnq
