import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import CustomInput from '../components/CustomInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone'
import { delImg, uploadImg } from '../features/upload/uploadSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCategories } from '../features/bcategory/bcategorySlice';
import { createBlogs, getABlog, resetState, updateABlog } from '../features/blogs/blogSlice';

const schema = Yup.object().shape({
  title: Yup.string().required('Please, Enter Blog Title'),
  description: Yup.string().required('Please, Enter Description'),
  category: Yup.string().required('Please, Enter Blog category'),
});


const Addblog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();  
  const getBlogId = location.pathname.split('/')[3];
  const blogState = useSelector((state) => state.blogs.blogs);
  const imageState = useSelector((state) => state.upload.images);
  const bCatState = useSelector((state) => state.bCategory.bCategories);
  const newBlog = useSelector((state) => state.blogs);
  console.log(imageState);
  const { isSuccess, isError, isLoading, createdBlog, blogName, blogDesc, blogCategory, blogImages, updatedBlog } = newBlog;
  useEffect(() => {
    if (getBlogId !== undefined) {
      dispatch(getABlog(getBlogId));
      img.push(blogImages);
    } else {
      dispatch(resetState());
    }
  }, [getBlogId]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    dispatch(resetState());
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (isSuccess && createdBlog) {
      toast.success('Done easy!');
    } if (updatedBlog && isSuccess) {
      toast.success('Done updated!');
      navigate('/admin/blog-list'); 
    } if (isError) {
      toast.error('Sorry, Something is Wrong!');
    }
  },[isSuccess, isError, isLoading]);

  const img = [];
  imageState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

   useEffect(() => {
    formik.values.images = img;
  }, [blogImages]);

  const formik = useFormik({
    enableReinitialize: true, // maximum depth error is here!
    initialValues: {
      title: blogName || '',
      description: blogDesc || '',
      category: blogCategory || '',
      images: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getBlogId !== undefined) {
        const data = { id: getBlogId, blogData: values};
        dispatch(updateABlog(data));
        dispatch(resetState());
      } else {
        dispatch(createBlogs(values));
      formik.resetForm();
      setTimeout(() => {
        dispatch(resetState());
      },300);
      }
    },
  });

  return (
    <div>
      <h3 className='mb-4'>{getBlogId !== undefined ? 'Edit' : 'Add'} Blog</h3>
      <div className=''>
        <form className='' onSubmit={formik.handleSubmit}>
          <div className='bg-dark border-1 p-3 mt-3 text-center text-white'>
            <Dropzone onDrop={acceptedFiles => dispatch(uploadImg(acceptedFiles))}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className='show-images mt-4 d-flex flex-wrap gap-3'>
            {imageState.map((i, j) => {
              return (
                <div key={j} className='position-relative'><button type='button' onClick={() => dispatch(delImg(i.public_id))} className='btn-close position-absolute' style={{top:'4px', right:'4px'}}></button><img src={i.url} alt='' width={200} height={200}/></div>
              )
            })
            }
          </div>
          <div className='mt-3'>
            <CustomInput type='text' label='Enter Blog Title' name='title' onCh={formik.handleChange('title')} onBlr={formik.handleBlur('title')} val={formik.values.title} />
          <div className='error mt-2'>
            {formik.touched.title && formik.errors.title}
          </div> 
          </div>
          <select name='category' className='form-control py-3 mb-3' id='category' onChange={formik.handleChange('category')} onBlur={formik.handleBlur('category')} value={formik.values.category}>
            <option value=''>Select Blog Category</option>
            {
              bCatState.map((i, j) => {
                return <option key={j} value={i.title}>{i.title}</option>
              })
            }
          </select>
          <div className='error mt-2'>
            {formik.touched.category && formik.errors.category}
          </div>
          <ReactQuill theme="snow" name='description' onChange={formik.handleChange('description')} value={formik.values.description} />
          <div className='error mt-2'>
            {formik.touched.description && formik.errors.description}
          </div>
          <button className='btn btn-success border-0 rounded-3 my-5' type='submit'>{getBlogId !== undefined ? 'Edit' : 'Add'} Blog</button>
        </form>
      </div>
    </div>
  )
}

export default Addblog
