import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import { getOrder } from '../features/auth/authSlice';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
  },
  {
    title: 'Product Name',
    dataIndex: 'name',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Color',
    dataIndex: 'color',
  },
  {
    title: 'Price',
    dataIndex: 'price',
  },
];

 
const ViewOrder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const orderId = location.pathname.split('/')[3];
  useEffect(() => {
    dispatch(getOrder(orderId));
  }, []);
  const orderState = useSelector((state) => state?.auth?.singleorder);
  const data1 = [];
  for (let i = 0; i < orderState?.orderItems?.length; i++) {
    data1.push({
      key: i + 1,
      name: orderState?.orderItems[i]?.productId.title, 
      brand: orderState?.orderItems[i]?.productId.brand,
      quantity: orderState?.orderItems[i]?.quantity,
      price: orderState?.orderItems[i]?.price,
      color: orderState?.orderItems[i]?.color.title,
    });
  }
  return (
    <div>
      <h3 className='mb-4'>View Order</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  )
}

export default ViewOrder
